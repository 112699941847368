@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  h1 {
    @apply text-3xl mb-1 text-gray-800;
  }
  h2 {
    @apply text-2xl mb-1 text-gray-800;
  }
  h3 {
    @apply text-xl mb-1 text-gray-800;
  }
  h4 {
    @apply text-lg mb-1 text-gray-800;
  }
  p {
    @apply mb-1 text-gray-800;
  }
  button {
    @apply border border-gray-200 bg-gray-100 rounded-md shadow-md px-2 py-1;
  }
  input {
    @apply border border-gray-200 rounded-md px-2 py-1;
  }
}

@layer components {
  .btn-primary {
    @apply bg-gray-800 text-white;
  }
}
